<template>
  <div class="water-view ly-wrap">
    <water-nav selected="1"></water-nav>
    <div class="error-wrap" v-if="isError">
      <div class="error-img"></div>
      <p>{{ errorMsg || '系统异常' }} </p>
    </div>

    <div class="ly-center water-view-wrap" v-else>
      <div class="nav-title"><i class="water-icon"></i>演示水厂 <span>数据刷新时间：{{ loadTime | formatData }}</span></div>
      <div class="water-wrap-bg">
        <!--gif水管-->
        <div class="gif-wrap">
          <div class="ab go-1 gf-g-4"></div>
          <div class="ab go-3 gf-g-1"></div>
          <div class="ab go-4 gf-g-3"></div>
          <div class="ab go-2 gf-g-12"></div>
          <div class="ab go-5 gf-g-5-b"></div>
          <div class="ab go-6 gf-g-6"></div>
          <div class="ab go-7 gf-g-4"></div>
          <div class="ab go-8 gf-g-4"></div>
          <div class="ab go-9 gf-b-4"></div>
          <div class="ab go-10 gf-b-4"></div>
          <div class="ab go-11 gf-b-4"></div>
          <div class="ab go-12 gf-b-4"></div>
          <div class="ab go-13 gf-b-8-b"></div>
          <div class="ab go-14 gf-b-7"></div>
          <div class="ab go-15 gf-b-3"></div>
          <div class="ab go-16 gf-b-1"></div>
          <div class="ab go-17 gf-b-4"></div>
          <div class="ab go-18 gf-y-3"></div>
          <div class="ab go-19 gf-y-1"></div>
          <div class="ab go-20 gf-y-3"></div>
          <div class="ab go-21 gf-y-1"></div>
          <div class="ab go-22 gf-y-3"></div>
          <div class="ab go-23 gf-y-1"></div>
          <div class="ab go-24 gf-y-4"></div>
          <div class="ab go-25 gf-y-8-b"></div>
          <div class="ab go-26 gf-y-3"></div>
          <div class="ab go-27 gf-y-10-b"></div>
          <div class="ab go-28 gf-b-10-b"></div>
        </div>
        <!--工艺组件-->
        <div class="parts-wrap">
          <div class="ab zj-z3 zo-1"></div>
          <div class="ab zj-z2 zo-2 pic-cdc"></div>
          <div class="ab zj-z2 zo-3 pic-cdc"></div>
          <div class="ab zj-z6 zo-4 pic-wflc"></div>
          <div class="ab zj-z6 zo-5 pic-wflc"></div>
          <div class="ab zj-z4 zo-6 pic-qsc"></div>
          <div class="ab zj-z4 zo-7 pic-qsc"></div>
          <div class="ab zj-z5 zo-8"></div>
        </div>
        <!--仪器仪表-->
        <div class="meter-wrap">
          <div class="ab mo-1 yq-y7 rt-lt"></div>
          <div class="ab mo-2 yq-y2 rt-rt"></div>
          <div class="ab mo-3 yq-y5"></div>
          <div class="ab mo-4 yq-y7 rt-lt"></div>
          <div class="ab mo-5 yq-y7 rt-rt"></div>
          <div class="ab mo-6 yq-y7 rt-lt"></div>
          <div class="ab mo-7 yq-y7 rt-rt"></div>
          <div class="ab mo-8 yq-y6"></div>
          <div class="ab mo-9 yq-y7"></div>
          <div class="ab mo-10 yq-y4"></div>
          <div class="ab mo-11 yq-y6"></div>
          <div class="ab mo-12 yq-y6"></div>
          <div class="ab mo-13 yq-y7"></div>
          <div class="ab mo-14 yq-y4"></div>
          <div class="ab mo-15 yq-y6"></div>
          <div class="ab mo-16 yq-y2 rt-lt"></div>
          <div class="ab mo-17 yq-y2 rt-rt"></div>
          <div class="ab mo-18 yq-y5 rt-lt"></div>
          <div class="ab mo-19 yq-y1 rt-lt"></div>
          <div class="ab mo-20 yq-y7 rt-lt"></div>
          <div class="ab mo-21 yq-y4 rt-lt"></div>
          <div class="ab mo-22 yq-y6"></div>
        </div>
        <!--文字-->
        <div class="txt-wrap">
          <div class="ab to-1 white-bg">
            <p class="title">进水浊度:</p>
            <p class="value">{{ waterData.SS0001.data }}NTU</p>
            <div class="ab sjx-wt-rf" style="top: 30%"></div>
          </div>
          <div class="ab to-2 white-bg">
            <p class="title">进水流量:</p>
            <p class="value">{{ waterData.FT0001.data }}m³/h</p>
            <div class="ab sjx-wt-lf" style="top: 30%"></div>
          </div>
          <div class="ab to-3 big-bg">
            <div class="big-title"><i class="i-pool"></i>配水井</div>
            <div class="big-txt">
              <p><span class="title">温度：</span><span class="value">{{ waterData.TT1001.data }}℃</span></p>
              <p><span class="title">PH：</span><span class="value">{{ waterData.PH1001.data }}</span></p>
            </div>
            <div class="ab sjx-wt-rf"></div>
          </div>
          <div class="ab to-4 big-bg">
            <div class="big-title"><i class="i-pool"></i>1号反应沉淀池</div>
            <div class="big-txt">
              <p>自动<span>排泥中</span></p>
            </div>
            <div class="ab sjx-wt-rf"></div>
          </div>
          <div class="ab to-5 big-bg">
            <div class="big-title"><i class="i-pool"></i>2号反应沉淀池</div>
            <div class="big-txt">
              <p>手动<span>未排泥</span></p>
            </div>
            <div class="ab sjx-wt-lf"></div>
          </div>
          <div class="ab to-6 white-bg">
            <p class="title">浊度：<span class="value">{{ waterData.SS1001A.data }}NTU</span></p>
            <div class="ab sjx-wt-rf" style="top: 15%;"></div>
          </div>
          <div class="ab to-7 white-bg">
            <p class="title">浊度：<span class="value">{{ waterData.SS1001B.data }}NTU</span></p>
            <div class="ab sjx-wt-lf" style="top: 15%;"></div>
          </div>
          <div class="ab to-8 big-bg">
            <div class="big-title"><i class="i-pool"></i>1号无阀滤池</div>
            <div class="ab sjx-bl-rf" style="top: 15%;"></div>
          </div>
          <div class="ab to-9 big-bg">
            <div class="big-title"><i class="i-pool"></i>2号无阀滤池</div>
            <div class="ab sjx-bl-lf" style="top: 15%;"></div>
          </div>
          <div class="ab to-10 white-bg">
            <p class="title">浊度：<span class="value">{{ waterData.SS2001A.data }}NTU</span></p>
            <div class="ab sjx-wt-rf" style="top: 15%;"></div>
          </div>
          <div class="ab to-11 white-bg">
            <p class="title">浊度：<span class="value">{{ waterData.SS2001B.data }}NTU</span></p>
            <div class="ab sjx-wt-lf" style="top: 15%;"></div>
          </div>
          <div class="ab to-12 big-bg">
            <div class="big-title"><i class="i-pool"></i>1号清水池</div>
            <div class="big-txt">
              <p>液位1：<span class="value">{{ waterData.LT3001A.data }}m</span></p>
              <p>浊度：<span class="value">{{ waterData.SS3001A.data }}NTU</span></p>
              <p>余氯：<span class="value">{{ waterData.CL3001A.data }}mg/L</span></p>
              <p>液位2：<span class="value">{{ waterData.LT3002A.data }}m</span></p>
              <div class="ab sjx-wt-rf"></div>
            </div>
          </div>
          <div class="ab to-13 big-bg">
            <div class="big-title"><i class="i-pool"></i>2号清水池</div>
            <div class="big-txt">
              <p>液位1：<span class="value">{{ waterData.LT3001B.data }}m</span></p>
              <p>浊度：<span class="value">{{ waterData.SS3001B.data }}NTU</span></p>
              <p>余氯：<span class="value">{{ waterData.CL3001B.data }}mg/L</span></p>
              <p>液位2：<span class="value">{{ waterData.LT3002B.data }}m</span></p>
              <div class="ab sjx-wt-lf"></div>
            </div>
          </div>
          <div class="ab to-14 white-bg">
            <p class="title">1号出口流量：</p>
            <p class="value">{{ waterData.FT4001A.data }}m³/h</p>
            <div class="ab sjx-wt-rf" style="top: 30%;"></div>
          </div>
          <div class="ab to-15 white-bg">
            <p class="title">2号出口流量：</p>
            <p class="value">{{ waterData.FT4002B.data }}m³/h</p>
            <div class="ab sjx-wt-lf" style="top: 30%;"></div>
          </div>
          <div class="ab to-16 big-bg">
            <div class="big-title"><i class="i-water"></i>末梢水质监测</div>
            <div class="big-txt">
              <p>温度：<span class="value">{{ waterData.TT4001.data }}℃</span></p>
              <p>PH：<span class="value">{{ waterData.PH4001.data }}</span></p>
              <p>压力：<span class="value">{{ waterData.PT4001.data }}Bar</span></p>
              <p>浊度：<span class="value">{{ waterData.SS4001.data }}NTU</span></p>
              <p>余氯：<span class="value">{{ waterData.CL4001.data }}mg/L</span></p>
              <div class="ab sjx-wt-rf"></div>
            </div>
          </div>
          <div class="ab to-17 big-bg">
            <div class="big-title"><i class="i-water"></i>末梢水质监测</div>
            <div class="big-txt ">
              <p>液位：<span>{{ waterData.LT5001.data }}m</span></p>
            </div>
            <div class="ab sjx-wt-dn"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-copyright">- 技术支持：云昇新控 -</div>

  </div>
</template>

<script>

import WaterNav from './modules/WaterNav.vue'
import {getSelectWater, getUserInfo} from '../../utils/auth'
import store from '../../store/index.js'
import {postWaterView,} from '../../api/api.js'
import moment from "moment";

export default {
  name: 'WaterView',
  components: {WaterNav},
  filters: {
    formatData(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  computed: {
    waterData() {
      return this.$store.state.sundries.waterViewData.result.data
    },
    loadTime() {
      return this.$store.state.sundries.waterViewData.collectTime
    }
  },
  data() {
    return {
      isError: false,
    }
  },
  mounted() {
    this.loadBase()
  },
  created() {
  },
  methods: {
    async loadBase() {
      const userInfo = await getUserInfo()
      const selectWater = await getSelectWater()
      const body = {
        waterId: selectWater.id,
        userId: userInfo.id
      }
      postWaterView(body).then(res => {
        this.isError = false
        if (res.data.success) {
          console.log(res.data)
          store.dispatch('WATER_APP_WATER', res.data)
        } else {
          this.isError = true
          this.errorMsg = res.data.message || '异常'
        }
      })
    },

  },
  destroyed: function () { // 离开页面生命周期函数
  },
}
</script>

<style scoped lang="less">
.water-view {
  width: 100%;
  min-height: 100%;
  background-color: #ffffff;
}

@import '../../assets/less/waterView.less';
@import '../../assets/less/view.less';
</style>

